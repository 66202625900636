<template>
    <v-row>
        <v-col
            cols="12"
            sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <!--v-card-title></1--v-card-title-->
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >
                    <v-card-actions>
                        <v-container fluid>
                            <v-btn color="primary"
                                   class-del="mb-6 ml-3"
                                   class="ml-3"
                                   :disabled="!isvalid"
                                   @click.prevent="onNext()"
                                   :loading="loadingBtn"
                            >
                                Взять талон

                            </v-btn>
                          <!-- Записаться к врачу -->
                            <!--v-btn @click.prevent="fillTestData()"
                                   class-del="mb-6 ml-3"
                                   class="ml-3"
                            >
                                filltestdata
                            </v-btn-->

                        </v-container>
                    </v-card-actions>

                    <v-card-text>
                        <v-form v-model="isvalid">
                            <template v-if="showFio">
                                <v-container fluid>
                                    <v-text-field
                                            ref="p_f"
                                            v-model="p_f"
                                            label="Фамилия"
                                            class-del="mt-8"
                                            placeholder="Иванов"
                                            :rules="fioRules"
                                    ></v-text-field>
                                    <v-row align="center">
                                        <v-col
                                                class="d-flex"
                                                cols="12"
                                                md="6"
                                        >
                                            <v-text-field
                                                    ref="p_i"
                                                    v-model="p_i"
                                                    label="Имя"
                                                    placeholder="Иван"
                                                    :rules="fioRules"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                class="d-flex"
                                                cols="12"
                                                md="6"
                                        >

                                            <v-text-field
                                                    ref="p_o"
                                                    v-model="p_o"
                                                    label="Отчество"
                                                    placeholder="Иванович"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <v-container fluid>
                                <v-row align="center">
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_dd"
                                                v-model="p_dd"
                                                :items="arr_dd"
                                                item-text="nname"
                                                item-value="num"
                                                label="День рождения"
                                                placeholder="01"
                                        ></v-select>

                                    </v-col>
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_mm"
                                                v-model="p_mm"
                                                :items="arr_mm"
                                                item-text="nname"
                                                item-value="num"
                                                label="Месяц"
                                                placeholder="Январь"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="4"
                                    >
                                        <v-select
                                                ref="p_yyyy"
                                                v-model="p_yyyy"
                                                :items="arr_yyyy"
                                                item-text="nname"
                                                item-value="num"
                                                label="Год"
                                                placeholder="1966"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container >
                            <template  v-if="showNum">
                                <v-container fluid>
                                    <v-row align="center">
                                        <v-col
                                                class="d-flex"
                                                cols="12"
                                                md="4"
                                        >
                                            <v-text-field
                                                    ref="p_ser"
                                                    v-model="p_ser"
                                                    label="Серия полиса (если есть)"
                                                    placeholder="Если есть"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                class="d-flex"
                                                cols="12"
                                                md="8"
                                        >
                                            <v-text-field
                                                    ref="p_num"
                                                    v-model="p_num"
                                                    label="Номер полиса"
                                                    placeholder="123456789012345"
                                                    :rules="numRules"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>
                            <v-container fluid>
                                <v-row align="center">
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="5"
                                    >
                                        <v-text-field
                                                ref="p_email"
                                                v-model="p_email"
                                                label="Email"
                                                placeholder="ivanov@mail.ru"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            class="d-flex"
                                            cols="12"
                                            md="7"
                                    >
                                        <v-text-field
                                                ref="p_tel"
                                                v-model="p_tel"
                                                label="Телефон для связи"
                                                placeholder="89080123456"
                                                :rules="telRules"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-container fluid>
                                <v-radio-group v-model="kardonhands">
                                    <v-radio
                                            key="1"
                                            label="Амбулаторная карта на руках"
                                            :value="true"
                                    ></v-radio>
                                    <v-radio
                                            key="0"
                                            label="Амбулаторная карта  в поликлинике"
                                            :value="false"
                                    ></v-radio>
                                </v-radio-group>

                                <v-checkbox
                                        v-model="p_agree"
                                        label="Согласен передать данные в обработку согласно ФЗ №152"
                                        :error="!p_agree"
                                ></v-checkbox>
                            </v-container>

                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-container fluid>
                        <v-btn color="primary"
                               class="mb-6 ml-3"
                               :disabled="!isvalid"
                               @click.prevent="onNext()"
                               :loading="loadingBtn"
                        >
                          Взять талон

                        </v-btn>
                        <!-- Записаться к врачу -->
                        <v-btn v-if="showBtnFilltestdata"
                               @click.prevent="fillTestData()"
                               class="mb-6 ml-3"
                        >
                            filltestdata
                        </v-btn>

                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"/>
                            <v-list-item-subtitle v-text="LPU.address"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="FIN.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="AGE.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MEDG.PANELCAPTION1"/>
                            <v-list-item-subtitle v-text="MEDG.PANELCAPTION2"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MED.NNAME"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="DDATE.DDATE"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="TTIME.NNAME"/>
                        </v-list-item-content>
                    </v-list-item>

                </v-card>
            </v-card>
        </v-col>

    </v-row>


</template>>

<style scoped>
    .invalid  {
     color: red;
    }


</style>
<script>
    import post from '../tools/post'
    import {postLog} from '../tools/post'
    import {toBack, toNext, thisLog} from '@/components/app/js/globals'
    import {doRest, doSave} from '@/components/app/js/kard'


    export default {
        name: 'Kard',
        data: () => ({
            name: 'Kard',
            header: 'Ваши данные',
            isvalid: false,
            loading: false,
            loadingBtn: false,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            //v => !!v || 'Обязательно для заполнения!',
            //v => /.+@.+/.test(v) || 'Некорректный E-mail'
            emailRules: [
                v => /.+@.+/.test(v) || 'Некорректный E-mail'

            ],
            fioRules:[
                v => !!v || 'Обязательно для заполнения!',
                v => ((v && v.trim()) || '').indexOf(' ') < 0 || 'Пробелы не допустимы',
                v => (v && v.length >1) || 'Должно быть больше одного символа',
                v => /^[A-ZА-ЯЁ\s-]+$/i.test(v) || 'Должны быть только буквы или дефис'
                //v => /^(\d+)$/.test(v) || 'Должны быть только буквы'
            ],
            /*
            telRules:[
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length == 11) || 'Телефон должен содержать 11 цифр'
            ],
            */
            telRules:[
                v => !!v || 'Обязательно для заполнения!'

            ],
            numRules:[
                v => !!v || 'Обязательно для заполнения',
                v => /^(\d+)$/.test(v) || 'Должны быть только цифры',
                v => (v && v.length >=6 && v.length <=16) || 'Количество цифр полиса должно быть от 6 до 16'
            ],
            p_f:     '',
            p_i:     '',
            p_o:     '',
            p_dd:    '',
            p_mm:    '',
            p_yyyy:  '',
            p_tel:   '',
            p_ser:   '',
            p_num:   '',
            p_email: '',
            p_agree: false,
            kardonhands: false,
            arr_dd: [],
            arr_mm: [
                {num: '01', nname: 'Январь', selected: false},
                {num: '02', nname: 'Февраль'},
                {num: '03', nname: 'Март'},
                {num: '04', nname: 'Апрель'},
                {num: '05', nname: 'Май'},
                {num: '06', nname: 'Июнь'},
                {num: '07', nname: 'Июль'},
                {num: '08', nname: 'Август'},
                {num: '09', nname: 'Сентябрь'},
                {num: '10', nname: 'Октябрь'},
                {num: '11', nname: 'Ноябрь'},
                {num: '12', nname: 'Декабрь'}
            ],
            arr_yyyy: []
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            MED() {
                return this.$store.getters.MED;
            },
            DDATE() {
                return this.$store.getters.DDATE;
            },
            TTIME() {
                return this.$store.getters.TTIME;
            },
            KARD() {
                return this.$store.getters.KARD;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                    (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1') &&
                    (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                    (this.$store.getters.MEDG.actual && this.$store.getters.MEDG.actual==='1') &&
                    (this.$store.getters.MED.actual && this.$store.getters.MED.actual==='1') &&
                    (this.$store.getters.DDATE.actual && this.$store.getters.DDATE.actual==='1') &&
                    (this.$store.getters.TTIME.actual && this.$store.getters.TTIME.actual==='1')
            },
            is_actualKARD() {
                return (this.$store.getters.KARD.actual && this.$store.getters.KARD.actual==='1')
            },
            is_f() {
                return this.$v.p_f.$invalid
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            },
            showFio(){
                return this.$store.getters.FIN.id === 'Pay'
            },
            showNum(){
                return this.$store.getters.FIN.id === 'Oms'
            },
            showBtnFilltestdata(){
                return (process.env.NODE_ENV === 'development')
            }
        },
        beforeMount() {
            try {

                if (this.is_actual) {
                    let max_det_age = 15
                    let year_b, year_e
                    const curyear = new Date().getFullYear()
                    if (this.$store.getters.AGE.id === 'Det') {
                        year_b = curyear - max_det_age
                        year_e = curyear
                    } else {
                        year_b = 1900
                        year_e = curyear - max_det_age
                    }

                    //заполнть список дней
                    for (let i = 0, dd = 1; dd <= 31; i++, dd++) {
                            this.arr_dd[i] = {num: (dd.toString().length<2?'0':'')+dd, nname: ''+dd, selected: false}
                    }

                    //заполнть список годов рождения
                    for (let i = 0, yyyy = year_b; yyyy <= year_e; i++, yyyy++) {
                        this.arr_yyyy[i] = {num: ''+yyyy, nname: ''+yyyy, selected: false}
                    }

                    //восстановить предыдущие значения
                    this.doRest()
                    //сбросить год рождения, если взрослый перешел на детскую карточку и наоборот
                    let yyyy=Number(this.p_yyyy)
                    //console.log('yyyy',yyyy, this.p_yyyy)
                    if(!(yyyy>=year_b && yyyy<=year_e)){
                        this.p_yyyy=''
                    }

                    //актуальность только по onNext!!!
                    this.$store.dispatch('SET_KARD_ACTUAL', '0')


                } else {
                    this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                    this.alertWarning.show = true
                    this.navBack()  //каскадный откат
                }
            }catch(e){
                alert(e)
                postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Kard#6' } )
            }
            this.loading=false

            //console.log('##$$$$$',this.p_dd, this.p_mm, this.p_yyyy)
        },
        beforeDestroy() {
            this.doSave()
        },
        methods: {
            fillTestData() {
                this.p_f='Кильсеев'
                this.p_i='Станислав'
                this.p_o='Владимирович'
                this.p_dd='28'
                this.p_mm='03'
                this.p_yyyy='1966'
                this.p_num='7448110874000229'
                this.p_tel='89080812360'
                this.p_agree=true
                this.p_email='m@m.ru'
                //console.log('navBack')
                //this.navBack()  //каскадный откат


            },
            doRest(){
                doRest(this)
                /*
                //console.log('doRest',this.is_actualKARD)
                //if(this.is_actual) {

                    //восстановить предыдущие значения из localStorage
                    if(this.$store.getters.FIN.id === 'Pay'){ //this.showFio()) {
                        this.p_f = localStorage.getItem('p_f')
                        this.p_i = localStorage.getItem('p_i')
                        this.p_o = localStorage.getItem('p_o')
                    }
                    this.p_dd = localStorage.getItem('p_dd')
                    this.p_mm = localStorage.getItem('p_mm')
                    this.p_yyyy = localStorage.getItem('p_yyyy')
                    this.p_tel = localStorage.getItem('p_tel')
                    if(this.$store.getters.FIN.id === 'Oms'){ //this.showNum()) {
                        this.p_ser = localStorage.getItem('p_ser')
                        this.p_num = localStorage.getItem('p_num')
                    }
                    this.p_email = localStorage.getItem('p_email')
                    this.p_agree = localStorage.getItem('p_agree')==='true'
                    this.kardonhands = localStorage.getItem('kardonhands')==='true'
                    //console.log('rest kardonhands',localStorage.getItem('kardonhands')==='true'))
                //}
                */
            },
            doSave(){
                doSave(this)
                /*
                //console.log('doSave',this.is_actualKARD)
                //if(this.is_actual) {

                    //сохранить значения из localStorage
                    if(this.$store.getters.FIN.id === 'Pay'){ //this.showFio()) {
                        if(this.p_f) {localStorage.setItem('p_f',this.p_f)}
                        if(this.p_i) {localStorage.setItem('p_i',this.p_i)}
                        if(this.p_o) {localStorage.setItem('p_o',this.p_o)}
                    }
                    if(this.p_dd) {localStorage.setItem('p_dd',this.p_dd)}
                    if(this.p_mm) {localStorage.setItem('p_mm',this.p_mm)}
                    if(this.p_yyyy) {localStorage.setItem('p_yyyy',this.p_yyyy)}
                    if(this.p_tel) {localStorage.setItem('p_tel',this.p_tel)}
                    if(this.$store.getters.FIN.id === 'Oms'){ //this.showNum()) {
                        if(this.p_ser) {localStorage.setItem('p_ser',this.p_ser)}
                        if(this.p_num) {localStorage.setItem('p_num',this.p_num)}
                    }
                    if(this.p_email) {localStorage.setItem('p_email',this.p_email)}
                    if(this.p_agree) {localStorage.setItem('p_agree',this.p_agree)}
                    if(this.p_kardonhands) {localStorage.setItem('kardonhands',this.kardonhands)}
                    //console.log('save kardonhands',this.kardonhands)
                //}

                 */
            },
            async navBack(){
                toBack(this)
            },
            async onNext() {
                try {
                    if (this.is_actual) {

                        this.loadingBtn = true

                        const v_opt = this.$store.getters.FIN.opt
                        const url = this.$store.getters.LPU.resturl + '/getkard'
                        thisLog(this, ['url', url])
                        const data = {
                            dd: this.p_dd, //(this.p_dd.toString().length < 2) ? '0' + this.p_dd : '' + this.p_dd,
                            mm: this.p_mm, //(this.p_mm.toString().length < 2) ? '0' + this.p_mm : '' + this.p_mm,
                            yyyy: this.p_yyyy, //'' + this.p_yyyy,
                            f: this.p_f,
                            i: this.p_i,
                            o: this.p_o,
                            opt: v_opt,
                            ser: this.p_ser,
                            num: this.p_num,
                            tel: this.p_tel,
                            email: this.p_email
                        }
                        try {
                            let res = await post(url, data)
                            thisLog(this, [` KARD >>>>>>>>> `, res])
                            if (res && res.data && res.data.length == 1) { //ответ получен
                                const el = res.data[0]
                                if (el.NPPKARD && el.NPPKARD.length > 0) {
                                    //if (el.NNAME && el.NNAME.length > 0) {
                                    //    pushNote(this, el.NNAME, {msg: true})
                                    //}
                                    let arr = {
                                        p_nppkard: el.NPPKARD,
                                        p_f: this.p_f,
                                        p_i: this.p_i,
                                        p_o: this.p_o,
                                        p_dd: this.p_dd,
                                        p_mm: this.p_mm,
                                        p_yyyy: this.p_yyyy,
                                        p_tel: this.p_tel,
                                        p_ser: this.p_ser,
                                        p_num: this.p_num,
                                        p_email: this.p_email,
                                        p_agree: this.p_agree,
                                        p_kardonhands: this.kardonhands,
                                        p_opt: v_opt
                                    }

                                    thisLog(this, ['kardOnHands--------------->> ', this.kardonhands])
                                    this.$store.dispatch('SET_KARD', arr)
                                    this.doSave()

                                    toNext(this)
                                } else {
                                    if (el.NNAME && el.NNAME.length > 0) {
                                        this.alertError.text = el.NNAME //описание ошибки
                                        this.alertError.show = true
                                        window.scrollTo(0, top)

                                        //pushNote(this, el.NNAME)
                                    }
                                }
                            } else {
                                this.alertWarning.text = "Что-то пошло не так"
                                this.alertWarning.show = true
                                window.scrollTo(0, top)
                                postLog(this.$store.getters.LPU.resturl, { err: err, place: 'kard#1', data: data} )

                                //this.navBack()  //каскадный откат
                                //pushNote(this, 'Что-то пошло не так')
                            }

                        } catch (err) {
                            this.alertError.text=err
                            this.alertError.show=true
                            window.scrollTo(0, top)
                            postLog(this.$store.getters.LPU.resturl, { err: err, place: 'kard#2' } )

                        }

                        //this.loadingBtn = false
                    } else {
                        this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                        this.alertWarning.show = true
                        window.scrollTo(0, top)
                        postLog(this.$store.getters.LPU.resturl, { warn: this.alertWarning.text, place: 'kard#3' } )

                        this.navBack()  //каскадный откат
                    }
                }catch(e){
                    alert(e)
                    postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Kard#4' } )
                }
                this.loadingBtn = false
            }
        }
    }
</script>
